import React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { Layout, Seo } from '@global'
import { Container, Button, Gradient, Title } from '@UI'
import { Fade } from '@animations'

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title='Page Not Found' />
      <Container
        section
        isSection
        style={{
          position: 'relative',
          minHeight: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Gradient />

        <Fade>
          <Container wrapperSm textBlock textCenter>
            <Title>Page Not Found</Title>
            <p>The page you were looking for does not exist.</p>
            <Button to='/'>Return Home</Button>
          </Container>
        </Fade>
      </Container>
    </Layout>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)
